/**** HEADER ****/
#header{
  z-index:9000;
  width:100%;
  top:0;
  position:sticky;
}

// .navbar{
//   color:#FFFFFF;
// }

// .navbar-burger{
//   color:#ffffff;
// }

// .navbar-burger:hover{
//   color:#ffffff;
// }


.navbar-logo{
  font-family: 'Bitter', serif;
  line-height:56px;
  color:#C30D20;
  font-weight:600;
  margin-right:50px;
  margin-left: 0.5em;
}

.navbar-logo:hover{
  color:#C30D20;
}

.navbar-logo img{
  height:40px;
  vertical-align:middle;
  margin-right:10px;
}

#patient-card{
  position:sticky;
  top:56px;
  padding-top:15px;
  padding-bottom:15px;
  z-index:3001;
  background:#ffffff;
  border-bottom: 3px solid #ededed;
  min-height: 82px;
}

#patient-card .panel-block{
  border-bottom: 0 !important;
}
