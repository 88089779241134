$pk-cell-bg: #36D1B2;
$efg-cell-bg:#36D1B2;
$date-cell-bg:#F14667;
$green-cell-bg:#298429;
$pastelgreen-cell-bg:#7fa563;
$pistache-cell-bg:#aee8bc;
$lightgreen-cell-bg:#7fd875;
$yellow-cell-bg:#e5df37;
$orange-cell-bg:#ce9335;
$red-cell-bg:#e54444;
$darkred-cell-bg:#9b2222;


#results-header{
  position:sticky;
  top:138px;
  z-index:600;
  background:#ffffff;
  box-shadow: unset;
  border-top:1px solid transparent;
  border-bottom:1px solid #f7f7f7;
  margin-bottom: 0px;
}

#dropdown-filters .dropdown-content {
  padding:15px;
}

/**** FAKE TABLE ****/
#legend-container, #fixed-container, #table-results-content {
  border-bottom: 1px solid #000;
}

#table-content{
  position:relative;
  padding:0 30px 0 30px;
  width:100vw;
  margin-bottom:50px;
}

#table-mask-left{
  position:absolute;
  height:100%;
  background:#ffffff;
  left:0;
  top:0;
  z-index:400;
}

.flex-container{
  display: flex;
}

#legend-container{
  min-width:300px;
  z-index:300;
  border-right: 1px solid #000;
}

#fixed-container{
  max-width:1000px;
  border-right: 2px solid #000;
  z-index:200;
  box-shadow: 5px 0 5px -2px rgba(0,0,0,0.3);
}

#scrollable-container{
  z-index:100;
  border-right: 1px solid #000;
}

#table-scroller{
  position:fixed;
  bottom:0;
  width:100%;
  height:17px;
  background:#fff;
  border-top:1px solid #f7f7f7;
  z-index:700;
}

#inner-table-scroller{
  position:absolute;
  top:1px;
  height:12px;
  background:#e7e7e7;
}

#scroll-handle{
  position:absolute;
  height:12px;
  width:100px;
  background:#c3c3c3;
  touch-action: none;
  user-select: none;
}

#scroll-handle:hover{
  background:#b1b1b1;
}

#interpretation-zone{
  position:absolute;
  height:100%;
  background:#ffffff;
  right:15px;
  top:0;
  z-index:400;
  border-left: 2px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  box-shadow: -5px 0 5px -2px rgba(0,0,0,0.3);
}

$sticky-header-base: 192px;

.th-date{
  position:sticky;
  top: $sticky-header-base + 30px;
  z-index:200;
}

.th-batchFusion{
  position:sticky;
  top: $sticky-header-base + 4px;
  z-index:200;
}

.th-samplingEndDate{
  position:sticky;
  top: $sticky-header-base + 30px;
  z-index:200;
}

.th-batchDate{
  position:sticky;
  top: $sticky-header-base + 60px;
  z-index:200;
}

.th-synthese{
  max-height: 285px;
  z-index:200;
  font-weight:bold;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer !important;
}

.th-synthese:hover {
  background-color: #f7f7f7  !important;
}

.flex-cell {
  border: none;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  font-size: 14px;

  &.nPrelevement{
    z-index:200;
    position:sticky;
    top: $sticky-header-base + 4px;
  }

  &.requestDate {
    z-index:200;
    position:sticky;
    top: $sticky-header-base + 30px;

  }

  &.samplingEndDate {
    z-index:210;
    position:sticky;
    top: $sticky-header-base + 30px;

  }

  &.batchDate,
  &.xaxis {
    z-index:200;
    position:sticky;
    top: $sticky-header-base + 60px;
  }


  &.date {
    background-color: $date-cell-bg;
    font-weight:bold;

    > .tools {
      box-sizing: border-box;
      padding: .2em;
      position: absolute;
      width: 100%;
      left: -100%;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      background: #fff;
      justify-content: flex-start;
      transition: left .5s;

      button {
        padding: .2em;
        height: 20px;
      }

      &.tools.show {
        left: 0;
      }

      .is-first {
        display: flex;
        flex-direction: row;
      }

      > .is-last {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &.xaxis {
    border-bottom: none;
    height: 50px !important;
    align-items: center;
    justify-content: start;
  }

  &.result{
    position: relative;
    z-index:100;
  }

  &.allele{
    position: relative;
    z-index:100;
  }

  &.generic{
    position: relative;
    z-index:100;
  }

  &.nb-b{
    border-bottom:0px !important;
  }

  &.nb-t{
    border-top:0px !important;
  }
}

.flex-column {
  flex-direction: column;
  line-height: 0.9;

  small {
    font-size: 0.7em;
  }
}

.flex-cell.date.active{
  color:#298429;
}

.flex-cell.status, .flex-cell.th-status {
  height:75px;
  max-height:75px;
}

.flex-cell.status {
  max-width: 150px;
}

.flex-cell.status:hover {
  background-color: #f7f7f7  !important;
}

.flex-cell.nLot{
  position:relative;
  font-size:10px;
  font-weight:bold;
}

.flex-cell.efg{
  background-color: $pk-cell-bg;
}

.flex-cell.pk{
  background-color: $efg-cell-bg;
}

.flex-cell.green{
  background-color: $green-cell-bg;
  color:white;
}

.flex-cell.pistache{
  background-color: $pistache-cell-bg;
}

.flex-cell.pastelgreen{
  background-color: $pastelgreen-cell-bg;
}

.flex-cell.lightgreen{
  background-color: $lightgreen-cell-bg;
  color:white;
}

.flex-cell.yellow{
  background-color: $yellow-cell-bg;
}

.flex-cell.orange{
  background-color: $orange-cell-bg;
}

.flex-cell.red{
  background-color: $red-cell-bg;
}

.flex-cell.darkred{
  background-color: $darkred-cell-bg;
  color:white;
}

.flex-cell .binary-indicator{
  width:10px;
  height: 100%;
  position:absolute;
  right:0;
  top:0;
}

.flex-cell .typage-indicator{
  width:30px;
  height:100%;
  position:absolute;


  &.patient{
    top:0;
    background-color:#3E8ED0;
    opacity: 0.7;
    right:0;
  }

  &.greffon{
    top:0;
    background-color:#F14667;
    left:0;
    opacity: 0.7;
  }
}

#interpretation-zone .flex-cell {
  overflow: visible;
  font-size: 12px;
  min-width: 80px;
}

.flex-cell .dropdown-menu{
  z-index:101;
  right:0;
  left:auto;
  min-width:0;
}

.delete-sample{
  position:absolute;
  top:0;
  right:0;
  display:none;
}

.nLot:hover .delete-sample{
  display:block;
}
